import React, { useState, useEffect, useContext, useRef } from "react";
import { useViewport } from "@headwaters-economics/web-shared";
import Tooltip from "./Tooltip";
import { Box } from "grommet";
import HeMapboxUber, {
  CensusLayer,
  ControlContainer
} from "@headwaters-economics/web-shared/components/Map";

import { Layer, Source } from "react-map-gl";

import appContext from "../../../context/appContext";
import mapTabContext from "../../../context/mapTabContext";
import appColors from "../../../appColors";
// import useThrottle from "@headwaters-economics/web-shared/hooks/useThrottle";
import FilterPills from "../FilterPills";
import DownloadButton from "../DownloadButton";

const mapboxID = "headmin.he-census-2023-e";

function Map({ isCombinedDataTableVisible, CombinedDataTable }) {
  const { selectedLocationId, selectedLocationMetadata } =
    useContext(appContext);
  const {
    selectedLocationExtent,
    tracts,
    // setIsMapLoaderVisible,
    isFilteringDisabled,
    setDownloadModalProps,
  } = useContext(mapTabContext);
  const { screenSize } = useViewport();
  const [mapExtent, set_mapExtent] = useState(null);
  // const [popupContent, set_popupContent] = useState(null);
  const [hoverFeature, set_hoverFeature] = useState(null);
  const [highlightFeature, set_highlightFeature] = useState(null);
  // const [cursorPosition, set_cursorPosition] = useState(null);
  const [popupPosition, set_popupPosition] = useState({});
  const [isPopupPinned, set_isPopupPinned] = useState(false);
  const isPopupPinnedRef = useRef();
  isPopupPinnedRef.current = isPopupPinned;


  useEffect(() => {
    if (selectedLocationExtent) {
      set_mapExtent(selectedLocationExtent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationExtent]);

  if (!mapExtent || !selectedLocationMetadata) {
    return null;
  }

  

  const Legend = () => {
    if (screenSize === "mobile") return null;
    return (
      <ControlContainer position="lower-left">
        <Box direction="row" gap="xsmall">
          <svg viewBox="0 0 16 16" width="16" height="16">
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              style={{
                fill: "transparent",
                strokeWidth: 2.5,
                stroke: "rgb(70,70,70)",
              }}
            />
          </svg>
          <label>{selectedLocationMetadata.label}</label>
        </Box>
        <Box direction="row" gap="xsmall">
          <svg viewBox="0 0 16 16" width="16" height="16">
            <rect
              x="0.5"
              y="0.5"
              width="15"
              height="15"
              style={{
                fill: appColors.included,
                strokeWidth: 1,
                stroke: appColors.included_border,
              }}
            />
          </svg>
          <label>
            {isFilteringDisabled ? "Census Tract" : "Tract that meets criteria"}
          </label>
        </Box>
        {!isFilteringDisabled && (
          <Box direction="row" gap="xsmall">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                style={{
                  fill: appColors.excluded,
                  strokeWidth: 1,
                  stroke: appColors.excluded_border,
                }}
              />
            </svg>
            <label>{"Tract that does not meet criteria"}</label>
          </Box>
        )}
      </ControlContainer>
    );
  };
  const tractList_include = tracts
    .filter((tract) => tract.include)
    .map((tract) => tract.id);
  return (
    <HeMapboxUber
      mapStyle="mapbox://styles/headmin/cko4vy98008zg17o6e70xacpy"
      // clickRadius={1}
      extent={mapExtent}
      // onFeatureHover={onFeatureHover}
      // popupContent={popupContent}
      screenSize={screenSize}
      // onMapLoad={(mr) => {
      //   setIsMapLoaderVisible(false);
      // }}
    >
      <ControlContainer
        position="top-left"
        plain
        style={{
          marginRight:
            isCombinedDataTableVisible && screenSize !== "mobile"
              ? "420px"
              : "50px",
        }}
      >
        <FilterPills />
      </ControlContainer>

      {screenSize !== "mobile" && tracts.length > 1 && (
        <ControlContainer
          position="top-right"
          plain
          style={{ maxWidth: "400px" }}
        >
          <Box plain>
            <CombinedDataTable />
            {isCombinedDataTableVisible && (
              <DownloadButton
                disabled={
                  tractList_include.length === 0 
                }
                label={"DOWNLOAD REPORT"}
                onClick={() =>
                  setDownloadModalProps({
                    label:
                      "Download a socioeconomic report for " +
                      tractList_include.length +
                      " selected locations.",
                    tracts: tracts,
                  })
                }
              />
            )}
          </Box>
        </ControlContainer>
      )}

      {(!isCombinedDataTableVisible || tracts.length === 1) &&
        screenSize !== "mobile" && (
          <ControlContainer position="lower-right" plain>
            <DownloadButton
              disabled={
                tractList_include.length === 0 
              }
              label={"DOWNLOAD REPORT"}
              onClick={() =>
                setDownloadModalProps({
                  label:
                    "Download a socioeconomic report for " +
                    tractList_include.length +
                    " selected locations.",
                  tracts: tracts,
                })
              }
            />
          </ControlContainer>
        )}

      <Legend />

      <Tooltip
        hoverFeature={hoverFeature}
        popupPosition={popupPosition}
        highlightFeature={highlightFeature}
        isPopupPinned={isPopupPinned}
        onCloseButtonClick={
          isPopupPinned &&
          (() => {
            set_isPopupPinned(false);
            set_popupPosition({});
            set_highlightFeature(null);
            set_hoverFeature(null);
          })
        }
      />

      <CensusLayer
        // disabled={isPopupPinned}
        layerName={"tract"}
        filterIds={tracts.map((tract) => tract.id)}
        // filter={["in", ["id"], tracts.map((tract) => tract.id)]}
        beforeId="road-label-small"
        beforeId_hoverLayer="road-label-medium"
        onHover={(ftr) => {
          if (!isPopupPinnedRef.current) {
            set_hoverFeature(ftr);
          }
        }}
        onClick={(ftr, e) => {
          set_popupPosition({
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
          });
          set_hoverFeature(ftr);
          set_isPopupPinned(true);
          set_highlightFeature({
            id: ftr.id,
            geo_level: "tract",
            name: ftr.properties.name,
          });
        }}
        selectedFeatures={tractList_include.map((tract) => ({
          id: tract,
          geo_level: "tract",
        }))}
        color_default_border={appColors.excluded_border}
        color_default_fill={appColors.excluded}
        color_hover_border={isPopupPinned?appColors.hover_pinned:appColors.hover}
        color_hover_fill={"transparent"}
        color_highlight_fill={"transparent"}
        color_select_border={appColors.included_border}
        color_select_fill={appColors.included}
        // color_label={colors.geos_selectBorder}
        // color_label_halo={"rgba(0,0,0,0.8)"}
        lineWidth_hoverLayer={3}
        highlightFeature={highlightFeature}
      />

      <Source id={"censusSrc1"} type="vector" url={"mapbox://" + mapboxID} />
      {selectedLocationMetadata && selectedLocationMetadata.geo_level && (
        <Layer
          id="lyr_seleted_location"
          source="censusSrc1"
          source-layer={selectedLocationMetadata.geo_level}
          type="line"
          paint={{
            "line-color": "rgb(70,70,70)",
            "line-width": 2.5,
          }}
          beforeId="road-label-small"
          filter={["in", ["id"], selectedLocationId]}
        />
      )}
    </HeMapboxUber>
  );
}

export default Map;
