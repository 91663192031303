import React, { useContext, useEffect } from "react";
import { useViewport, getRelativeHeadingSize } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import { Box, Button, Text, Stack, Image, Menu, Layer, Anchor } from "grommet";

import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";
import coverPhoto from "../assets/images/startscreen.jpg";
import demoThumb from "../assets/images/demo_thumb.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlayCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import ReactPlayer from "react-player";

function StartScreen() {
    const { setLocation, searchCache } = useContext(appContext);
    const { screenSize } = useViewport();
    const [show, setShow] = React.useState();

    useEffect(() => {
        const videoElement = document.getElementById("coverVideo");
        if (videoElement) videoElement.play();
    }, []);

    return (
        <Stack fill anchor={screenSize === "mobile" ? "bottom" : "center"}>
            {screenSize !== "desktop" ? (
                <Image fill fit="cover" src={coverPhoto} />
            ) : (
                <Box fill>
                    <video
                        id="coverVideo"
                        autoplay
                        muted
                        loop
                        style={{ position: "fixed", right: 0, bottom: 0, minWidth: "100%", minHeight: "100%" }}
                        className="startscreen-video"
                        poster={coverPhoto}
                    >
                        <source
                            src="https://headwaterseconomics.org/wp-content/uploads/nar_v2_startscreen.mp4"
                            type="video/mp4"
                        />
                    </video>
                </Box>
            )}

            <Box width={screenSize === "mobile" ? "100%" : "large"} pad={{ bottom: "25vh" }}>
                <Box fill={screenSize === "mobile" ? "horizontal" : false}>
                    <Box>
                        <Text
                            style={{ textShadow: "0 0  3px #000" }}
                            textAlign="start"
                            color="white"
                            size={getRelativeHeadingSize("large", screenSize)}
                        >
                            Select a community, county, or state to identify
                        </Text>
                        <Text
                            style={{ textShadow: "0 0  3px #000" }}
                            margin={{ bottom: "small" }}
                            weight="bold"
                            textAlign="start"
                            color="white"
                            size={getRelativeHeadingSize("xxlarge", screenSize)}
                        >
                            Neighborhoods at Risk
                        </Text>
                    </Box>
                    <Box
                        background={"#D8852A"}
                        direction="row"
                        round="small"
                        gap="xsmall"
                        pad={{ vertical: "xsmall", horizontal: "small" }}
                        margin={{ bottom: "small" }}
                    >
                        <Text weight={"bolder"} color='light-1'>NEW!</Text>{" "}
                        <Text color='light-1'>Wildfire risk data now available.</Text>
                    </Box>
                    <Box pad={"none"} background="white" border>
                        <LocationPicker
                            screenSize={screenSize}
                            onSelection={(place) => setLocation(place, "map")}
                            placeholder={"Which ccommunity, Indigenous area, county, or state?"}
                            geoTypes={["he-place","he-county","he-state","he-tribal"]}
                            searchCache={searchCache}
                            table="he.geos_nar_2023_vw"
                        />
                    </Box>
                    <Box
                        margin={{ vertical: "medium" }}
                        pad="small"
                        background="rgba(225,225,225,.95)"
                        direction={screenSize === "mobile" ? "column" : "row"}
                        gap="small"
                        align="center"
                        width={screenSize === "mobile" ? "" : "medium"}
                        alignSelf="center"
                        round="xsmall"
                        elevation="small"
                        animation="fadeIn"
                        onClick={() => setShow(true)}
                    >
                        <Anchor>
                            <Box
                                round="xxsmall"
                                width="small"
                                overflow="hidden"
                                border={{ size: "small", color: "light-1" }}
                            >
                                <Stack anchor="center">
                                    <Image src={demoThumb} fill />
                                    <FontAwesomeIcon icon={faPlayCircle} size="2x" color="#333333" />
                                </Stack>
                            </Box>
                        </Anchor>
                        <Text color="dark-1" weight="bold">
                            How to use Neighborhoods at Risk
                        </Text>
                        {show && (
                            <Layer
                                onEsc={() => setShow(false)}
                                onClickOutside={() => setShow(false)}
                                background="dark-1"
                            >
                                <Box round="xsmall" overflow="hidden" background="dark-1">
                                    <Box direction="row" justify="between" pad="small">
                                        <Text>How to use Neighborhoods at Risk</Text>
                                        <Button
                                            onClick={(event) => {
                                                setShow(false);
                                                event.stopPropagation();
                                            }}
                                            plain
                                            label={<FontAwesomeIcon icon={faTimesCircle} size="lg" />}
                                        />
                                    </Box>
                                    <ReactPlayer
                                        controls={true}
                                        width="1280px"
                                        height="720px"
                                        url="https://vimeo.com/577801267"
                                    />
                                </Box>
                            </Layer>
                        )}
                    </Box>
                </Box>
            </Box>
            <Layer responsive={false} plain animation="none" modal={false} position={"top-right"}>
                <Box background="light-1" margin="xsmall" elevation="small">
                    <Menu
                        dropProps={{
                            align: { top: "bottom", left: "right" },
                            elevation: "xlarge",
                        }}
                        icon={<FontAwesomeIcon icon={faBars} size="lg" color="dark-1" />}
                        alignSelf="start"
                        label="Menu"
                        plain
                        margin="xsmall"
                        items={[
                            { label: "Headwaters Economics", href: "https://headwaterseconomics.org" },
                            {
                                label: "About this tool",
                                href: "https://headwaterseconomics.org/tools/neighborhoods-at-risk/tool-about/",
                            },
                            { label: "How to use this tool", href: "https://vimeo.com/577801267/1f8a6d6322" },
                            { label: "See more tools", href: "https://headwaterseconomics.org/tools" },
                            { label: "Contact us", href: "https://headwaterseconomics.org/contact/" },
                        ]}
                    />
                </Box>
            </Layer>
        </Stack>
    );
}
export default StartScreen;
